.container {
  &:not(:last-child) {
    margin: 0 0 30px;
  }

  & > :not(:last-child) {
    margin: 0 0 20px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px;
}

.label {
  color: var(--color-white);
  flex-shrink: 0;
  font-family: var(--font-family-sans);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-s);
  padding-left: 20px;
}
