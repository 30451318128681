.container {
  background-color: var(--color-black);
  left: 0;
  padding: 20px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--z-index-header);
}

.inner {
  align-items: center;
  display: flex;
  justify-content: space-between;

  & > * {
    display: flex;
    flex-shrink: 0;

    &:nth-child(2) {
      justify-content: center;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }
}

.buttonContainer {
  align-items: center;
  display: flex;

  & > :not(:last-child) {
    margin: 0 20px 0 0;
  }
}

.logoContainer {
  flex-shrink: 0;

  & a,
  & svg {
    display: block;
  }
}

.button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  height: 20px;
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
  width: 20px;

  & svg {
    color: var(--color-white);
    fill: var(--color-white);
    height: 20px;
    width: 20px;
  }
}

.avatarButton {
  text-decoration: none;
}

.loaderContainer {
  align-items: center;
  display: flex;

  & * {
    height: 20px;
    width: 20px;
  }
}

.logoMenuContainer {
  align-items: center;
  display: flex;

  & > :not(:last-child) {
    margin: 0 20px 0 0;
  }

  @media (--viewport-m) {
    & > :not(:last-child) {
      margin: 0 40px 0 0;
    }
  }
}

.menuButton {
  @media (--viewport-m) {
    display: none;
  }
}

.menu {
  align-items: flex-start;
  background-color: var(--color-black);
  display: none;
  flex-direction: column;
  left: 0;
  padding: 10px 24px 24px;
  position: absolute;
  top: 60px;
  width: 100%;

  &.isOpen {
    display: flex;
  }

  & > :not(:last-child) {
    margin: 0 0 16px;
  }

  @media (--viewport-m) {
    align-items: center;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    padding: 0;
    position: relative;
    top: 0;
    width: auto;

    & > :not(:last-child) {
      margin: 0 20px 0 0;
    }
  }
}

.menuItem {
  color: var(--color-white);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-medium);
  line-height: 1;
  opacity: 0.4;
  text-decoration: none;
  transition: color var(--transition);

  &:hover,
  &:focus {
    opacity: 1;
  }

  &.active {
    border-bottom: solid 1px var(--color-white);
    opacity: 1;
  }
}
