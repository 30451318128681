.form {
  max-width: var(--container-s);

  & > :not(:last-child) {
    margin: 0 0 12px;
  }
}

.error {
  color: var(--color-red);
  display: block;
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-s);
}

.input {
  background-color: transparent;
  border-radius: var(--border-radius);
  border: 1px solid var(--color-border);
  box-sizing: border-box;
  color: var(--color-white);
  display: block;
  font-family: var(--font-family-mono);
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
  outline: 0;
  outline: none;
  padding: 10px;
  resize: none;
  transition: border-color 200ms ease-out;
  width: 100%;

  &:hover,
  &:focus {
    border-color: var(--color-offwhite);
  }

  @media (--viewport-m) {
    font-size: var(--font-size-l);
    padding: 12px;
  }
}

.inputContainer {
  display: block;
}

.inputLabel {
  color: var(--color-white);
  display: block;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-s);
  margin: 0 0 0.3em;

  @media (--viewport-m) {
    font-size: var(--font-size-m);
  }
}

.inputError {
  color: var(--color-red);
  display: block;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-m);
  margin: 0.3em 0 0;

  @media (--viewport-m) {
    font-size: var(--font-size-s);
  }
}

.inputDescription {
  color: var(--color-offwhite);
  display: block;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-m);
  margin: 0.3em 0 0;

  @media (--viewport-m) {
    font-size: var(--font-size-s);
  }
}
