.container {
  position: relative;
}

.bubble {
  align-items: center;
  background-color: var(--color-green);
  border-radius: 50%;
  color: var(--color-black);
  display: flex;
  font-family: var(--font-family-mono);
  font-size: var(--font-size-xxs);
  font-weight: var(--font-weight-medium);
  height: 20px;
  justify-content: center;
  line-height: 1;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -30%);
  width: 20px;
}
