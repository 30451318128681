.button {
  background-color: var(--color-green);
  border-radius: var(--border-radius);
  border: 0;
  color: var(--color-black);
  cursor: pointer;
  display: inline-block;
  font-family: var(--font-family-mono);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-medium) !important;
  line-height: var(--line-height-s);
  padding: 6px 12px;
  position: relative;
  text-decoration: none;
  transition: background-color 200ms ease-out, color 200ms ease-out, opacity 200ms ease-out;

  & span {
    color: var(--color-black);
  }

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  @media (--viewport-m) {
    font-size: var(--font-size-m);
    padding: 8px 14px;
  }

  &.secondary {
    background-color: transparent;
    color: var(--color-white);

    &:hover,
    &:focus {
      background-color: var(--color-white);
      color: var(--color-black);
    }
  }

  &.small {
    font-size: var(--font-size-xs);
    padding: 3px 6px;

    @media (--viewport-m) {
      font-size: var(--font-size-s);
    }
  }
}

.labelLoading {
  visibility: hidden;
}

.loader {
  align-items: center;
  display: flex;
  height: var(--font-size-s);
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: var(--font-size-s);

  & svg {
    height: var(--font-size-s);
    width: var(--font-size-s);
  }
}
