.loader {
  display: inline-block;

  & svg {
    animation: loaderAnimation 1s cubic-bezier(0.6, 0, 0.4, 1) infinite;
  }
}

.alignCenter {
  align-items: center;
  display: flex;
  justify-content: center;
}

@keyframes loaderAnimation {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }
}
