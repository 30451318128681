.container {
  margin: 0 auto;
  max-width: var(--container-l);
  padding: 0 24px;
  width: 100%;

  @media (--viewport-m) {
    padding: 0 72px;
  }
}
