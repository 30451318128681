.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.childrenContainer {
  padding: 90px 0 30px; /* 30px + header height */
  flex: 1;

  @media (--viewport-m) {
    padding: 110px 0 30px; /* 50px + header height */
  }
}
