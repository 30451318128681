.container {
  max-width: var(--container-m);

  & h1,
  & h2,
  & h3 {
    color: var(--color-white);
    font-family: var(--font-family-sans);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-s);
    margin: 0;

    &:not(:last-child) {
      margin: 0 0 0.5em;
    }
  }

  & h1 {
    font-size: var(--font-size-xl);

    @media (--viewport-m) {
      font-size: var(--font-size-xxl);
    }

    & span {
      background-color: var(--color-green);
      color: var(--color-black);
    }
  }

  & h2 {
    font-size: var(--font-size-l);

    @media (--viewport-m) {
      font-size: var(--font-size-xl);
    }
  }

  & h3 {
    font-size: var(--font-size-m);

    @media (--viewport-m) {
      font-size: var(--font-size-l);
    }
  }

  & p {
    font-family: var(--font-family-mono);
    font-size: var(--font-size-m);
    line-height: var(--line-height-m);
    color: var(--color-offwhite);
    margin: 0;

    &:not(:last-child) {
      margin: 0 0 1.25em;
    }

    @media (--viewport-m) {
      font-size: var(--font-size-l);
    }
  }

  & strong {
    color: var(--color-white);
  }

  & a {
    color: var(--color-white);
    font-weight: var(--font-weight-bold);
  }
}
