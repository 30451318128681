.avatarContainer {
  align-items: center;
  background-color: var(--color-blue);
  border-radius: 50%;
  color: var(--color-white);
  display: flex;
  font-family: var(--font-family-mono);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  height: 24px;
  justify-content: center;
  line-height: 1;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  width: 24px;

  @media (--viewport-m) {
    font-size: var(--font-size-s);
    height: 28px;
    width: 28px;
  }
}

.avatar {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
